import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useConfigurationService } from "~/src/services/ConfigurationService";
import { DEFAULT_REQUEST, useCaseTableStore } from "~/stores/caseTableStore";
import type { FrontendRequestObject } from "~/src/models/utils/Api.model";
import { useState } from "#app";
import type { FilterConfigResponse } from "~/src/models/utils/Filter.model";

export const useCaseFilters = () => {
  const caseTableStore = useCaseTableStore();
  const { request } = storeToRefs(caseTableStore);
  const hasInitialised = useState("hasCaseFiltersInitialised", () => false);
  const filterConfig = useState<undefined | FilterConfigResponse>(
    "filterConfig",
    () => undefined
  );
  const { getCaseTableFilterConfigCached } = useConfigurationService();

  const init = async () => {
    if (hasInitialised.value) return;

    filterConfig.value = await getCaseTableFilterConfigCached();

    hasInitialised.value = true;
  };

  const defaultFilter = computed(() => filterConfig.value?.defaultFilter);
  const defaultVal = ref<FrontendRequestObject>(DEFAULT_REQUEST);
  const setDefaultFilter = (filter: FrontendRequestObject["filters"]) =>
    (defaultVal.value.filters = filter);

  const resetRequest = async () => {
    if (!hasInitialised.value) await init();
    request.value = defaultVal.value;

    hasInitialised.value = true;
  };

  if (defaultFilter.value) setDefaultFilter([defaultFilter.value]);

  return {
    init,

    defaultVal,
    filterConfig,
    resetRequest,
  };
};
